import { Envs } from "./enums"

const publicKey = {
  [Envs.DEV]: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAw0yAJC2Zac7yzy1B7gZt
Ub7jEwplf+7widFWjdEU+YYgItvO50Ay5Zb1+t315IWiHzdylOhhw2qWdUmSwhlE
/7MOlNNZ06R6P1ZR2tQRGsOsAiTH7xzpq0iSmveIiL/c3FUBuh7rIgwEuCjGNJq8
wPLrzeP+DbK3QbTeedM2rjv5wYcg7Dyc5sa5yoa/b3EHXFcndF7EHNAvmBhb+pxJ
yVEBgbrlnr7AAiF6LWjBx3dZvAxXh6M5MHcCeTxu6nhQKjin/OBZ6UG75eZ7nypE
scv6H4Qx5Xmhwomj1fKOE3RQJV1QM38y4lmDl2cJlrhlTL+zjIydTV2DjHoGF/PY
ewIDAQAB
-----END PUBLIC KEY-----`,
  [Envs.UAT]: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAw0yAJC2Zac7yzy1B7gZt
Ub7jEwplf+7widFWjdEU+YYgItvO50Ay5Zb1+t315IWiHzdylOhhw2qWdUmSwhlE
/7MOlNNZ06R6P1ZR2tQRGsOsAiTH7xzpq0iSmveIiL/c3FUBuh7rIgwEuCjGNJq8
wPLrzeP+DbK3QbTeedM2rjv5wYcg7Dyc5sa5yoa/b3EHXFcndF7EHNAvmBhb+pxJ
yVEBgbrlnr7AAiF6LWjBx3dZvAxXh6M5MHcCeTxu6nhQKjin/OBZ6UG75eZ7nypE
scv6H4Qx5Xmhwomj1fKOE3RQJV1QM38y4lmDl2cJlrhlTL+zjIydTV2DjHoGF/PY
ewIDAQAB
-----END PUBLIC KEY-----`,
  [Envs.QA]: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAw0yAJC2Zac7yzy1B7gZt
Ub7jEwplf+7widFWjdEU+YYgItvO50Ay5Zb1+t315IWiHzdylOhhw2qWdUmSwhlE
/7MOlNNZ06R6P1ZR2tQRGsOsAiTH7xzpq0iSmveIiL/c3FUBuh7rIgwEuCjGNJq8
wPLrzeP+DbK3QbTeedM2rjv5wYcg7Dyc5sa5yoa/b3EHXFcndF7EHNAvmBhb+pxJ
yVEBgbrlnr7AAiF6LWjBx3dZvAxXh6M5MHcCeTxu6nhQKjin/OBZ6UG75eZ7nypE
scv6H4Qx5Xmhwomj1fKOE3RQJV1QM38y4lmDl2cJlrhlTL+zjIydTV2DjHoGF/PY
ewIDAQAB
-----END PUBLIC KEY-----`,
  [Envs.SANDBOX]: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAw0yAJC2Zac7yzy1B7gZt
Ub7jEwplf+7widFWjdEU+YYgItvO50Ay5Zb1+t315IWiHzdylOhhw2qWdUmSwhlE
/7MOlNNZ06R6P1ZR2tQRGsOsAiTH7xzpq0iSmveIiL/c3FUBuh7rIgwEuCjGNJq8
wPLrzeP+DbK3QbTeedM2rjv5wYcg7Dyc5sa5yoa/b3EHXFcndF7EHNAvmBhb+pxJ
yVEBgbrlnr7AAiF6LWjBx3dZvAxXh6M5MHcCeTxu6nhQKjin/OBZ6UG75eZ7nypE
scv6H4Qx5Xmhwomj1fKOE3RQJV1QM38y4lmDl2cJlrhlTL+zjIydTV2DjHoGF/PY
ewIDAQAB
-----END PUBLIC KEY-----`,
  [Envs.PROD]: `-----BEGIN RSA PUBLIC KEY-----
MIIBCgKCAQEAoxd+zi4cLNIz4GAJqFnolB5zQex5l1EcPa4xL5sr5U6pTlPBS8yA
9VXrMepUM512wApJ8UXs45SVut7p7LJ/c1B8L7hn19CQG44z3xHE/HK1tV5cnu/p
sUOs1Suy2nXj8Zm+83RvPSltp0UCyr1So1htvMq6ND5iHfBZZRMKmLx4f2QG8siG
cqys6BT0MY60aiZQl6+aKGstC9JDvGmrPhbb7AzkdxNn7n2iPnOe7G2ty0pHr4g0
J54m46ev/93dm1OqKpYMH7yInL4/TaNyTQLn1CRa39jBBKJAJ5B7NbvrMG455eqZ
w3xvOMYS/yMNcb4mVvsBSDy4XXVHXKHg4wIDAQAB
-----END RSA PUBLIC KEY-----`,
  [Envs.AU_SANDBOX]: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAw0yAJC2Zac7yzy1B7gZt
Ub7jEwplf+7widFWjdEU+YYgItvO50Ay5Zb1+t315IWiHzdylOhhw2qWdUmSwhlE
/7MOlNNZ06R6P1ZR2tQRGsOsAiTH7xzpq0iSmveIiL/c3FUBuh7rIgwEuCjGNJq8
wPLrzeP+DbK3QbTeedM2rjv5wYcg7Dyc5sa5yoa/b3EHXFcndF7EHNAvmBhb+pxJ
yVEBgbrlnr7AAiF6LWjBx3dZvAxXh6M5MHcCeTxu6nhQKjin/OBZ6UG75eZ7nypE
scv6H4Qx5Xmhwomj1fKOE3RQJV1QM38y4lmDl2cJlrhlTL+zjIydTV2DjHoGF/PY
ewIDAQAB
-----END PUBLIC KEY-----`,
  [Envs.AU_PROD]: `-----BEGIN RSA PUBLIC KEY-----
MIIBCgKCAQEAoxd+zi4cLNIz4GAJqFnolB5zQex5l1EcPa4xL5sr5U6pTlPBS8yA
9VXrMepUM512wApJ8UXs45SVut7p7LJ/c1B8L7hn19CQG44z3xHE/HK1tV5cnu/p
sUOs1Suy2nXj8Zm+83RvPSltp0UCyr1So1htvMq6ND5iHfBZZRMKmLx4f2QG8siG
cqys6BT0MY60aiZQl6+aKGstC9JDvGmrPhbb7AzkdxNn7n2iPnOe7G2ty0pHr4g0
J54m46ev/93dm1OqKpYMH7yInL4/TaNyTQLn1CRa39jBBKJAJ5B7NbvrMG455eqZ
w3xvOMYS/yMNcb4mVvsBSDy4XXVHXKHg4wIDAQAB
-----END RSA PUBLIC KEY-----`,
  [Envs.FED_PROD]: `-----BEGIN RSA PUBLIC KEY-----
MIIBCgKCAQEAoxd+zi4cLNIz4GAJqFnolB5zQex5l1EcPa4xL5sr5U6pTlPBS8yA
9VXrMepUM512wApJ8UXs45SVut7p7LJ/c1B8L7hn19CQG44z3xHE/HK1tV5cnu/p
sUOs1Suy2nXj8Zm+83RvPSltp0UCyr1So1htvMq6ND5iHfBZZRMKmLx4f2QG8siG
cqys6BT0MY60aiZQl6+aKGstC9JDvGmrPhbb7AzkdxNn7n2iPnOe7G2ty0pHr4g0
J54m46ev/93dm1OqKpYMH7yInL4/TaNyTQLn1CRa39jBBKJAJ5B7NbvrMG455eqZ
w3xvOMYS/yMNcb4mVvsBSDy4XXVHXKHg4wIDAQAB
-----END RSA PUBLIC KEY-----`,
}

export { publicKey }
