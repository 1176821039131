import React, { useState, useRef, useEffect, useImperativeHandle } from "react"
import { useSelector } from "react-redux"

const OtpInput = React.forwardRef((props, ref) => {
  const length = props.length || 4
  const [otp, setOtp] = useState(Array(length).fill(""))
  const inputRefs = useRef([])

  const config = useSelector(state => state.config)
  const theme = useSelector(state => state.theme)
  const cssConfig = {
    ...props?.module_level_css,
    ...config?.designConfig?.styles?.input?.otpInput,
  }
  const isValid = props.isValid
  const isResendClicked = props?.isResendClicked
  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, length)
  }, [length])

  useImperativeHandle(ref, () => ({
    focus: index => {
      if (inputRefs.current[index]) {
        inputRefs.current[index].focus()
      }
    },
    focusLast: () => {
      let otpLength = otp.join("").length
      if (otpLength > 0) {
        inputRefs.current[otpLength - 1].focus()
      }
    },
    focusFirst: () => {
      if (length > 0) {
        inputRefs.current[0].focus()
      }
    },
  }))

  const handleInputChange = (index, e) => {
    e.preventDefault()
    const value = e.target.value
    const newOtp = [...otp]

    if (value.length > 1) {
      const otpArray = value.split("").slice(0, length)
      otpArray.forEach((digit, i) => {
        newOtp[i] = digit
      })

      setOtp(newOtp)
      props.onChange(newOtp.join(""))
      return
    }

    newOtp[index] = value

    // Handle backspace to clear the current digit
    if (value === "" && index > 0) {
      // Only clear the current digit without affecting previous ones
      inputRefs.current[index].focus()
    }

    setOtp(newOtp)
    props.onChange(newOtp.join(""))

    // Automatically focus on the next input if available
    if (index < length - 1 && value !== "") {
      inputRefs.current[index + 1].focus()
    }
    if (index >= props.maxInputSize - 1) {
      window.scrollTo(0, 0)
      inputClass += " blur"
      inputRefs.current.forEach(key => {
        if (key && inputRefs.current[index].value !== "") {
          key.blur()
        }
      })
    }
  }

  const handleKeyDown = (index, e) => {
    // Allow only numeric characters and handle backspace
    if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
      e.preventDefault()
    }

    if (e.key === "ArrowLeft" && index > 0) {
      inputRefs.current[index - 1].focus()
    } else if (e.key === "Backspace" && index > 0 && !otp[index]) {
      // Handle backspace when the current input is empty
      inputRefs.current[index - 1].focus()
    } else if (
      e.key === "Backspace" &&
      index === 0 &&
      !otp[index] &&
      props.goToPreviousInput
    ) {
      props.goToPreviousInput()
    }
  }

  const handlePaste = e => {
    e.preventDefault()
    const pastedData = e.clipboardData.getData("text/plain").split("")
    if (pastedData?.length !== length) {
      return
    }
    const newOtp = Array(length).fill("")
    pastedData.slice(0, length).forEach((digit, index) => {
      if (/^\d$/.test(digit)) {
        newOtp[index] = digit
      }
    })

    setOtp(newOtp)
    inputRefs.current[0].blur()
    props.onChange(newOtp.join(""))
  }

  let inputClass = "v3-otp-digit-input"
  if (!props.isValid) {
    inputClass += " v3-otp-digit-invalid"
  }

  useEffect(() => {
    if ((!isValid || isResendClicked) && !props.isSetFlow) {
      const emptyArray = otp.fill("")
      setOtp(emptyArray)
      inputRefs.current[0].focus()
    }
  }, [isValid, isResendClicked])

  return (
    <div
      className='v3-otp-input-container'
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: props.type === "mpin" ? "space-around" : "space-evenly",
        gap: "10px",
      }}
    >
      {Array.from({ length }, (_, index) => (
        <input
          key={index}
          type='tel'
          inputMode='tel'
          className={inputClass}
          autoComplete='one-time-code'
          value={props?.isHashed ? (otp[index] ? "*" : "") : otp[index]}
          onChange={e => handleInputChange(index, e)}
          onKeyDown={e => handleKeyDown(index, e)}
          ref={input => (inputRefs.current[index] = input)}
          onPaste={handlePaste}
          maxLength={1}
          {...cssConfig}
          style={{
            backgroundColor:
              props.type === "login"
                ? "transparent"
                : theme.v3.cssVars.leadingWhite,
            borderBottom: `1px solid ${!props.error ? theme.v3.cssVars.tertiaryNeutral.color2 : theme.v3.cssVars.tertiaryNegative.color1}`,
          }}
        />
      ))}
    </div>
  )
})

export default OtpInput
