import { getApiConfig } from "../utils/functions"
import Service from "./Service"

const AuthService = {
  getDeviceToken: data => Service.post(`/get-device-token`, data),
  getValidateSession: data => Service.post(`/validate-session`, data),
  pwaLogin: apiToken =>
    Service.post(`accounts/auth/login`, {}, getApiConfig(apiToken)),
}

export default AuthService
