import React from "react"
import { useTranslation } from "react-i18next"
import { ColorButton } from "./buttons"
import mpinBlockedImage from "../../assets/images/v3/mPinBlockedV3.svg"
import { useState, useEffect } from "react"
import Cross from "../svg/v3/Cross"
import { hexToRGBA } from "../../utils/functions"
import { useSelector } from "react-redux"
import telephone from "../../assets/images/v3/telephone.svg"
import mail from "../../assets/images/v3/mail.svg"
import { BottomSheet } from "react-spring-bottom-sheet"
import { showCancelButton } from "../../utils/auth"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import { goToRedirectUrl } from "../../utils/functions"
import { useDispatch } from "react-redux"
import {
  restoreStateBeforeSetMpin,
  saveStateBeforeSetMpin,
  setVerifiedChallenges,
} from "../../store/actions/Auth"
import {
  setSetMpinApiToken,
  setSetMpinState,
} from "../../store/actions/SetMpin"
import { AuthenticationType } from "../../utils/enums"
import { Header } from "./headers"
import MpinExpiredImage from "../../assets/images/v3/MpinExpiredImage.svg"

export const AuthBlocked_v3 = ({ type, timeUntilBlocked }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const authStore = useSelector(state => state.auth)
  const mpinWord = screen?.mpin?.setMpin?.mpinCharacterCase || "mPIN"
  const [timeLeftText, setTimeLeftText] = useState("")
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false)

  const handleResetMpinClick = () => {
    let verifiedChallenges = { ...authStore.verifiedChallenges }
    verifiedChallenges.mpinRefId = null
    // on mpin block, delete mpin ref id and prev token if available.
    dispatch(setVerifiedChallenges({ verifiedChallenges }))
    dispatch(setSetMpinApiToken({ apiToken: null }))
    dispatch(saveStateBeforeSetMpin())
    dispatch(
      setSetMpinState({
        onSetMpinSuccess: async () => {
          dispatch(restoreStateBeforeSetMpin())
          navigate("/Auth/Mpin", { replace: true })
        },

        onSetMpinFailure: async (error, message) => {
          dispatch(restoreStateBeforeSetMpin())
          await authStore.onAuthFailure(
            t("mpin.failedMpinSetupMessage"),
            t("mpin.genericErrorText"),
          )
        },

        onSetMpinCancel: async message => {
          dispatch(restoreStateBeforeSetMpin())
          // on cancel navigate back here
          navigate("/Auth/Mpin", { replace: true })
        },
      }),
    )

    navigate("/Auth/SetMpin", { replace: true })
  }

  const handleContactSupportClick = () => {
    setIsBottomSheetOpen(true)
  }

  const padNumber = (number, digits) => {
    return String(number).padStart(digits, "0")
  }

  const onClose = () => setIsBottomSheetOpen(false)

  const bottomSheetDetails = () => {
    return (
      <div className='v3-customerSupport-bottomSheet'>
        <div
          className='v3-customerSupport-bottomSheet-header'
          style={{
            color: theme.v3.cssVars.tertiaryNeutral.color1,
            marginBottom: "23px",
            marginTop: "24px",
          }}
        >
          <div className='v3-customerSupport-bottomSheet-title'>
            {t("mpin.helpAndSupportText")}
          </div>
          <div
            onClick={onClose}
            className='v3-customerSupport-bottomSheet-closeButton'
          >
            <Cross color={theme.v3.rawColors.tertiaryNeutral.color1} />
          </div>
        </div>
        <div
          className='v3-customerSupport-bottomSheet-contacts'
          onClick={() =>
            (window.location.href = `tel:${screen?.customerCare?.moblieNo}`)
          }
        >
          <img
            className='v3-customerSupport-bottomSheet-contacts-image'
            alt='telephone'
            src={telephone}
          />
          <div className='v3-customerSupport-bottomSheet-contacts-text'>
            <div>{t("mpin.customerServiceText")}</div>
            <div>{screen?.customerCare?.moblieNo}</div>
          </div>
        </div>
        <div className='v3-customerSupport-bottomSheet-contacts-divider'>
          {"Or"}
        </div>
        <div
          className='v3-customerSupport-bottomSheet-contacts'
          onClick={() =>
            (window.location.href = `mailto:${screen?.customerCare?.email}`)
          }
        >
          <img
            className='v3-customerSupport-bottomSheet-contacts-image'
            alt='mail'
            src={mail}
          />
          <div className='v3-customerSupport-bottomSheet-contacts-text'>
            <div>{t("mpin.customerCareContact")}</div>
            <div>{screen?.customerCare?.email}</div>
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (
      type === AuthenticationType.MPIN ||
      type == AuthenticationType.MPIN_EXPIRED
    )
      return
    let initialTimeLeftInSeconds = moment(timeUntilBlocked).diff(
      moment(),
      "seconds",
    )
    let timeLeftInSeconds = initialTimeLeftInSeconds
    const timer = setInterval(() => {
      let textToDisplay = ""
      if (timeLeftInSeconds <= 0) {
        showCancelButton() ? navigate("/") : goToRedirectUrl()
      } else if (timeLeftInSeconds < 60) {
        textToDisplay = `00:${padNumber(timeLeftInSeconds, 2)} seconds`
      } else if (timeLeftInSeconds < 3600) {
        let min = padNumber(Math.floor(timeLeftInSeconds / 60), 2)
        let seconds = padNumber(timeLeftInSeconds % 60, 2)
        textToDisplay = `${min}:${seconds} minutes`
      } else {
        let hour = padNumber(Math.floor(timeLeftInSeconds / 3600), 2)
        let min = padNumber(
          Math.floor((timeLeftInSeconds - hour * 3600) / 60),
          2,
        )
        textToDisplay = `${hour}:${min} hours`
      }
      setTimeLeftText(textToDisplay)
      timeLeftInSeconds--
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <>
      <Header />
      <div className='v3_mpin_blocked_section'>
        {type === AuthenticationType.MPIN_EXPIRED ? (
          <div className='v3-mpin_expired_image_text_container'>
            <img
              src={MpinExpiredImage}
              className='v3-mpin-expired-image'
              alt='mpin expired'
            />
            <div className='v3-mpin-expired-text'>
              <div>Your MPIN has expired!</div>
              <div>Please reset your MPIN now to regain access</div>
            </div>
          </div>
        ) : (
          <div className='v3_mpin_block_image_text_container'>
            <div className='v3_mpin_block_image'>
              <img src={mpinBlockedImage} alt='mpin v3 block image' />
            </div>
            <div className='v3_mpin_block_card_header_text'>
              {t("mpin.accessBlockedHeader")}
            </div>
            <div className='v3_mpin_block_card_description_text'>
              {type === AuthenticationType.MPIN
                ? `We noticed several incorrect ${mpinWord} attempts. Please reset your ${mpinWord} to keep your account secure.`
                : type === AuthenticationType.OTP
                  ? `We have temporarily blocked your access due to several unsuccessful OTP attempts to keep your account safe. `
                  : "We have temporarily blocked your access due to several unsuccessful identity verification attempts to keep your account safe."}
            </div>
            {type !== AuthenticationType.MPIN && (
              <div className='v3_mpin_block_card_description_timelefttext'>
                {`Please retry after ${timeLeftText || "sometime"}.`}
              </div>
            )}
          </div>
        )}
      </div>
      {type === AuthenticationType.MPIN ||
      type == AuthenticationType.MPIN_EXPIRED ? (
        <div
          className={`v3_mpin_block_reset_mpin_button ${type === AuthenticationType.MPIN_EXPIRED ? "v3_mpin_expired_reset_button_container" : ""}`}
        >
          <ColorButton
            text={`${t("mpin.mpinResetBtnText")}`}
            onPress={handleResetMpinClick}
            isDisable={false}
            width={"100%"}
          />
        </div>
      ) : (
        <div className='v3_otp_block_contact_support_section'>
          {t("mpin.requireAssistanceText")}{" "}
          <div
            className='v3_otp_block_contact_support_text'
            onClick={handleContactSupportClick}
          >
            {t("mpin.contactSupport")}
          </div>
        </div>
      )}
      <BottomSheet open={isBottomSheetOpen} onDismiss={onClose}>
        {bottomSheetDetails()}
      </BottomSheet>
    </>
  )
}
