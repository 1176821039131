import React, { useState } from "react"
import { useSelector } from "react-redux"
import { BottomSheet } from "react-spring-bottom-sheet"
import moment from "moment"
import { WhiteButton } from "../../core/buttons"
import { useNavigate } from "react-router-dom"
import {
  RewardsRecordType,
  RewardsRedirectionType,
  benefitType,
  offerType,
} from "../../../utils/constants"
import {
  copyToClipboard,
  formatToWholeCurrency,
  truncateTransactionId,
} from "../../../utils/functions"
import TransactionBottomSheetHeader from "./TransactionBottomSheetHeader"
import RewardsAccordian from "./RewardsAccordian"
import TransactionCopyToClipboard from "../../svg/v3/TransactionCopyToClipboard"
import RewardTransactionList from "./RewardTransactionList"
import { ToastType } from "../../../utils/enums"
import { customToast } from "../../../core"

const RewardTransactionListWithBottomSheet = ({
  transactionList,
  isExpiring = false,
  isReward = false,
  isCashback = false,
}) => {
  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const navigate = useNavigate()

  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false)
  const [selectedTransaction, setSelectedTransaction] = useState(null)

  const rewardRedirectionType = screen?.home?.reward?.redirectionTypesV3

  const {
    primary: { color1: primaryColor1 },
  } = theme?.v3?.rawColors

  const handleTransactionClick = transaction => {
    setSelectedTransaction(transaction)
    setIsBottomSheetOpen(true)
  }

  const handleBottomSheetCloseButtonClick = () => {
    setIsBottomSheetOpen(false)
  }

  const handleMilestoneButtonClick = benefitId => {
    if (
      selectedTransaction?.benefitDetails?.offerType ===
      offerType.PROGRAM_OFFERS
    ) {
      navigate(`/CvpOffers/${benefitId}`)
    } else {
      navigate(`/CampaignOffers/${benefitId}`)
    }
  }

  const handleCopyToClipboardClick = text => {
    if (!navigator?.clipboard) {
      copyToClipboard(text)
      customToast(ToastType.COPY, `Transaction ID Copied!`, {
        toastTextColor: "var(--tertiary-neutral-color-1)",
        toastBackgroundColor: "var(--tertiary-neutral-color-1)",
      })
    } else {
      navigator?.clipboard?.writeText(text)
      customToast(ToastType.COPY, `Transaction ID Copied!`, {
        toastTextColor: "var(--tertiary-neutral-color-1)",
        toastBackgroundColor: "var(--tertiary-neutral-color-1)",
      })
    }
  }

  const getAccordianHeader = transaction => {
    if (transaction?.benefitDetails?.benefitType === benefitType?.MILESTONE) {
      return "Milestone Details"
    } else if (transaction?.recordType === RewardsRecordType.REDEEMED) {
      return "Redemption Details"
    } else {
      return "View Transaction Details"
    }
  }

  const getAccordianContent = transaction => {
    if (
      rewardRedirectionType?.includes(RewardsRedirectionType.LR_TRANSACTIONS)
    ) {
      return (
        <div className='v3-rewards-cashback-accordian-transaction-details'>
          <div>
            <div>Transaction Amount</div>
            <div>
              {formatToWholeCurrency(Number(transaction?.metadata?.txnAmount))}
            </div>
          </div>
          <div>
            <div>Date & Time</div>
            <div>
              {moment(transaction?.metadata?.txnDate).format(
                "DD MMM YYYY, hh:mm A",
              )}
            </div>
          </div>
        </div>
      )
    } else if (transaction?.transactionDetails) {
      return (
        <div className='v3-rewards-cashback-accordian-transaction-details'>
          <div>
            <div>Transaction Amount</div>
            <div>
              {formatToWholeCurrency(
                Number(transaction?.transactionDetails?.amount),
              )}
            </div>
          </div>
          <div>
            <div>Transaction ID</div>
            <div>
              <div>
                {truncateTransactionId(transaction?.transactionDetails?.id)}
              </div>
              <div
                className='v3-rewards-cashback-accordian-copy-to-clipboard'
                onClick={() =>
                  handleCopyToClipboardClick(
                    transaction?.transactionDetails?.id,
                  )
                }
              >
                <TransactionCopyToClipboard color={primaryColor1} />
              </div>
            </div>
          </div>
          <div>
            <div>Date & Time</div>
            <div>
              {moment(transaction?.transactionDetails?.txnDate).format(
                "DD MMM YYYY, hh:mm A",
              )}
            </div>
          </div>
          {/* <div>
            <div>Payment Mode</div>
            <div>{selectedTransaction?.transactionDetails?.paymentMode}</div>
          </div> */}
        </div>
      )
    } else if (
      transaction?.benefitDetails?.benefitType === benefitType.MILESTONE
    ) {
      return (
        <div className='v3-rewards-cashback-accordian-redemption-details'>
          <div>
            {moment(transaction?.activityDateTime).format(
              "DD MMM YYYY, hh:mm A",
            )}
          </div>
          <div>{transaction?.benefitDetails?.description}</div>
        </div>
      )
    } else if (transaction?.recordType === RewardsRecordType.REDEEMED) {
      return (
        <div className='v3-rewards-cashback-accordian-redemption-details'>
          <div>
            {moment(transaction?.activityDateTime).format(
              "DD MMM YYYY, hh:mm A",
            )}
          </div>
          <div>{transaction?.narration}</div>
        </div>
      )
    }
  }

  return (
    <>
      <RewardTransactionList
        transactionList={transactionList}
        onTransactionClick={handleTransactionClick}
        isExpiring={isExpiring}
        isReward={isReward}
        isCashback={isCashback}
      />
      <BottomSheet
        open={isBottomSheetOpen}
        onDismiss={handleBottomSheetCloseButtonClick}
      >
        <div className='v3-rewards-cashback-bottomSheet'>
          <TransactionBottomSheetHeader
            transaction={selectedTransaction}
            isReward={isReward}
            isCashback={isCashback}
            onCrossBtnClick={handleBottomSheetCloseButtonClick}
          />
          <RewardsAccordian
            header={getAccordianHeader(selectedTransaction)}
            content={getAccordianContent(selectedTransaction)}
            isOpen
          />
          {selectedTransaction?.benefitDetails?.benefitType ===
            benefitType.MILESTONE && (
            <div className='v3-rewards-cashback-transaction-bottom-sheet-cta-container'>
              <WhiteButton
                text='Go to Milestone'
                width='100%'
                onPress={() =>
                  handleMilestoneButtonClick(
                    selectedTransaction?.benefitDetails?.benefitId,
                  )
                }
              />
            </div>
          )}
        </div>
      </BottomSheet>
    </>
  )
}

export default RewardTransactionListWithBottomSheet
