export const publicPaths = ["/get-device-token", "/public/program-config"]

export const encryptedPathsPatterns = [
  /^\/cards\/(.+)\/reset-pin$/,
  /^\/cards\/(.+)\/unmask-card$/,
]

export const unEncryptedPathsPatterns = [
  /\/getSessionToken(\?.*)?$/,
  /\/get-device-token(\?.*)?$/,
  /\/send-heartbeat(\?.*)?$/,
  /\/auth\/login(\?.*)?$/,
]
