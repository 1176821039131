const CryptoJS = require("crypto-js")
const forge = require("node-forge")

const encryptionUtil = {
  generateCekkey: () => {
    // Define the character set to use for the key
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

    // Define the length of the key (16 bytes for AES-128)
    const keyLength = 32

    let key = ""

    // Generate random characters for the key
    for (let i = 0; i < keyLength; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length)
      key += charset.charAt(randomIndex)
    }

    return key
  },
  aesEncryptData: (data, cekKey) => {
    function concatIVAndCipher(iv, ciphertext) {
      const ivBytes = iv.words
      const ciphertextBytes = ciphertext.words
      const concatenatedBytes = ivBytes.concat(ciphertextBytes)
      const concatenatedWordArray =
        CryptoJS.lib.WordArray.create(concatenatedBytes)
      const byteArray = CryptoJS.enc.Hex.parse(concatenatedWordArray.toString())

      return byteArray
    }
    const key = CryptoJS.enc.Utf8.parse(cekKey)
    const iv = CryptoJS.lib.WordArray.random(16)
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    })
    const encryptedData = concatIVAndCipher(iv, encrypted.ciphertext)
    const base64String = CryptoJS.enc.Base64.stringify(encryptedData)
    return base64String
  },

  aesDecryptData: (ciphertext, cekKey) => {
    const decodedKey = CryptoJS.enc.Utf8.parse(cekKey)
    const decodedCiphertext = CryptoJS.enc.Base64.parse(ciphertext)
    const iv = CryptoJS.lib.WordArray.create(
      decodedCiphertext.words.slice(0, 4),
    )
    const encryptedData = CryptoJS.lib.WordArray.create(
      decodedCiphertext.words.slice(4),
    )
    const decrypted = CryptoJS.AES.decrypt(
      {
        ciphertext: encryptedData,
      },
      decodedKey,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      },
    )
    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8)
    return JSON.parse(decryptedString)
  },
  rsaEncryptData: (aesKey, publicKey) => {
    const publicKeyObj = forge.pki.publicKeyFromPem(publicKey)
    const encryptedData = publicKeyObj.encrypt(aesKey)
    return forge.util.encode64(encryptedData)
  },
  rsaDecryptData: (encryptedAesKey, privatekeyPEM) => {
    try {
      const privateKeyObj = forge.pki.privateKeyFromPem(privatekeyPEM)
      const encrypted = forge.util.decode64(encryptedAesKey)
      const decryptedData = privateKeyObj.decrypt(encrypted)
      return decryptedData
    } catch (error) {
      console.error("Decryption error:", error)
      return null
    }
  },
}

module.exports = encryptionUtil
