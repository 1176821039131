import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import Close_v3 from "../../../svg/v3/close"
import statement from "../../../../assets/images/v3/active/statementdefault.svg"
import resetPin from "../../../../assets/images/v3/resetPin.svg"
import resetMpin from "../../../../assets/images/v3/resetMpin.svg"
import cardSettings from "../../../../assets/images/v3/cardSettings.svg"
import blockReplace from "../../../../assets/images/v3/blockReplace.svg"
import customerCare from "../../../../assets/images/v3/customerCare.svg"
import aboutCard from "../../../../assets/images/v3/aboutCard.svg"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setSetMpinState } from "../../../../store/actions/SetMpin"
import {
  AU_BlockCodes,
  BottomSheetType,
  cardSettingsOptions,
  Federal_BlockCodes,
  ToastType,
} from "../../../../utils/enums"
import { useBottomSheet } from "../../../auth/BottomSheetContext"
import WarningTriangle from "../../../svg/v3/warningTriangle"
import { customToast } from "../../../../core/Toast"
import SettingsArrowRight from "../../../svg/v3/settingsArrowRight"
import { getCardSettingsOptions } from "../../../../utils/functions"

const BottomSheet_v3 = ({ setOptionsOpen }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const screen = useSelector(state => state.screen)
  const user = useSelector(state => state.user)
  const session = useSelector(state => state.session)
  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)

  const isSetMpinAndSetCardPinDisabled =
    user?.cards[0]?.isHotlisted ||
    user?.cards[0]?.isLocked ||
    session?.disabledFunctionsByBlockCode?.includes(
      AU_BlockCodes.TEMP_CARD_DISABLE,
    ) ||
    session?.disabledFunctionsByBlockCode?.includes(
      AU_BlockCodes.PERMANENT_CARD_DISABLE,
    ) ||
    session?.disabledFunctionsByBlockCode?.includes(
      Federal_BlockCodes.TEMP_CARD_DISABLE,
    ) ||
    session?.disabledFunctionsByBlockCode?.includes(
      Federal_BlockCodes.PERMANENT_CARD_DISABLE,
    )

  const settingsHomeBottomContainerRef = useRef(null)
  const bottomSheetContext = useBottomSheet()

  const dispatch = useDispatch()
  const onChangeMpinClick = async () => {
    setOptionsOpen(false)
    bottomSheetContext.openBottomSheet2(BottomSheetType.SET_MPIN)
    dispatch(
      setSetMpinState({
        onSetMpinSuccess: async () => {},
        onSetMpinFailure: async () => {
          // toast("Failed to set MPIN")
          navigate("/", { replace: true })
        },
        onSetMpinCancel: async () => {
          navigate("/", { replace: true })
        },
      }),
    )
    // navigate to set mpin screen
    // navigate("/Auth/SetMpin", { replace: true, state: { isChangeMpin: true } })
  }

  useEffect(() => {
    const element = settingsHomeBottomContainerRef.current
    setTimeout(() => {
      element.classList.add("active")
    }, 3)
    return () => {
      element.classList.remove("active")
    }
  }, [])

  const renderToast = () => {
    const temproarilyBlockedText = t("settings.cardTemproarilyBlocked")
    const permanentlyBlockedText = t("settings.cardPermanentlyBlocked")
    if (
      user?.cards[0]?.isHotlisted ||
      session?.disabledFunctionsByBlockCode?.includes(
        AU_BlockCodes.PERMANENT_CARD_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode?.includes(
        Federal_BlockCodes.PERMANENT_CARD_DISABLE,
      )
    ) {
      customToast(ToastType.INFO, permanentlyBlockedText, {
        toastTextColor: theme.v3.rawColors.tertiaryNeutral.color1,
        toastBackgroundColor: theme.v3.rawColors.tertiaryCaution.color1,
      })
    } else if (
      user?.cards[0]?.isLocked ||
      session?.disabledFunctionsByBlockCode?.includes(
        AU_BlockCodes.TEMP_CARD_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode?.includes(
        Federal_BlockCodes.TEMP_CARD_DISABLE,
      )
    ) {
      customToast(ToastType.INFO, temproarilyBlockedText, {
        toastTextColor: theme.v3.rawColors.tertiaryNeutral.color1,
        toastBackgroundColor: theme.v3.rawColors.tertiaryCaution.color1,
      })
    }
  }

  const mpinWord = screen?.mpin?.setMpin?.mpinCharacterCase || "mPIN"

  const settings = [
    {
      logo: statement,
      title: t("settings.StatementTitle"),
      desc: t("settings.StatementDescription"),
      navigateTo: "/statement",
      isEnabled: screen?.aboutProgram?.isEnabled,
      isBlocked: false,
    },
    {
      id: cardSettingsOptions.RESET_PIN,
      logo: resetPin,
      title: t("settings.ResetPinTitle"),
      desc: t("settings.ResetPinDescription"),
      callBottomSheetFunction: () => {
        setOptionsOpen(false)
        bottomSheetContext.openBottomSheet2(BottomSheetType.RESET_PIN)
      },
      isEnabled: screen?.mpin?.changeCardPin?.isEnabled,
      isActive: user,
      isBlocked: isSetMpinAndSetCardPinDisabled,
    },
    {
      id: cardSettingsOptions.RESET_MPIN,
      logo: resetMpin,
      // title: `Reset ${mpinWord}`,
      // desc: "Change your Mobile PIN",
      title: t("settings.ResetMpinTitle", {
        mpinWord: mpinWord,
      }),
      desc: t("settings.ResetMpinDescrption"),
      callBottomSheetFunction: () => {
        onChangeMpinClick()
      },
      isEnabled: screen?.mpin?.resetMpin?.isEnabled,
      isBlocked: isSetMpinAndSetCardPinDisabled,
    },
    {
      logo: cardSettings,
      title: "Manage Saved Cards",
      desc: "Add/remove merchants linked to your card",
      navigateTo: "/ManageCard/Cards/Saved",
      isEnabled: config?.jsonConfig?.isTokenisationEnabled != false,
    },
    {
      logo: customerCare,
      title: t("settings.CustomerCareTitle"),
      desc: t("settings.CustomerCareDescription"),
      navigateTo: "/ManageCard/CustomerCare",
      isEnabled: screen?.customerCare?.isEnabled,
      isBlocked: false,
    },
    {
      logo: aboutCard,
      title: t("settings.AboutCardTitle"),
      desc: t("settings.AboutCardDescription"),
      navigateTo: "/ManageCard/AboutProgram",
      isEnabled: screen?.aboutProgram?.isEnabled,
      isBlocked: false,
    },
    {
      id: cardSettingsOptions.BLOCK_REPLACE_CARD,
      logo: blockReplace,
      title: t("settings.BlockReplaceTitle"),
      desc: t("settings.BlockReplaceDescription"),
      navigateTo: "/ManageCard/BlockCardReason",
      isEnabled: screen?.blockReplaceCard?.isEnabled,
      isBlocked: isSetMpinAndSetCardPinDisabled,
    },
  ]

  const cardSettingsOptionsList = getCardSettingsOptions(settings)

  return (
    <div
      ref={settingsHomeBottomContainerRef}
      className='v3-settings-home-bottom-container'
    >
      <div className='v3-settings-home-bottom'>
        <div className='v3-settings-home-bottom-head'>
          <div>{t("settings.moreCardActions")}</div>
          <div onClick={() => setOptionsOpen(false)}>
            <Close_v3 />
          </div>
        </div>

        {settings.map(item => {
          if (item?.isEnabled) {
            return (
              <div
                className='v3-settings-home-bottom-item'
                onClick={() =>
                  !item?.isBlocked
                    ? item.callBottomSheetFunction
                      ? item.callBottomSheetFunction()
                      : navigate(item.navigateTo)
                    : renderToast()
                }
                style={{
                  opacity:
                    item?.isBlocked &&
                    cardSettingsOptionsList.includes(item?.id) &&
                    "0.5",
                }}
              >
                <div className='v3-settings-home-bottom-item-icon'>
                  <img src={item.logo} width={"40px"} height={"40px"} />
                </div>
                <div className='v3-settings-home-bottom-item-body'>
                  <div className='v3-settings-home-bottom-item-body-title'>
                    {item.title}
                  </div>
                  <div className='v3-settings-home-bottom-item-body-desc'>
                    {item.desc}
                  </div>
                </div>

                <div className='v3-settings-home-button-item-cta-container'>
                  {item?.isBlocked &&
                    cardSettingsOptionsList.includes(item?.id) && (
                      <div className='v3-settings-home-button-item-disabled-cta-icon'>
                        <WarningTriangle />
                      </div>
                    )}
                  <div className='v3-settings-home-bottom-item-cta'>
                    <SettingsArrowRight
                      color={theme.v3.rawColors.primary.color1}
                    />
                  </div>
                </div>
              </div>
            )
          } else {
            return <></>
          }
        })}
      </div>
    </div>
  )
}

export default BottomSheet_v3
