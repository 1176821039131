import React, { forwardRef, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { BottomSheet } from "react-spring-bottom-sheet"
import Cross from "../../../svg/v3/Cross"
import Divider from "../../../core/Divider/v3/Divider"
import { ColorButton } from "../../../core/buttons"
import Info from "../../../svg/info"
import {
  captureEvents,
  resetErrorTryAgainCount,
} from "../../../../utils/functions"
import { EventName } from "../../../../utils/enums"
import { useDispatch, useSelector } from "react-redux"
import AccountService from "../../../../services/AccountService"
import {
  setPgTransaction,
  setPgVPA,
} from "../../../../store/actions/PaymentGateway"
import { useNavigate } from "react-router-dom"
import { ErrorType } from "../../../../utils/constants"
import { ErrorContext } from "../../../auth/ErrorScreenContext"

const PaymentUpiVerifyV3 = forwardRef(({ isOpen, onClose, ref }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { setErrorState } = useContext(ErrorContext)
  const [upiId, setUpiId] = useState("")
  const [isError, setIsError] = useState(false)
  const [accHolderName, setAccHolderName] = useState("")
  const [vpaTxnId, setVpaTxnId] = useState("")
  const [submitLoading, setSubmitLoading] = useState(false)

  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const paymentGateway = useSelector(state => state.paymentGateway)

  useEffect(() => {
    if (isOpen) {
      captureEvents({
        eventName: EventName.PAY_BILL_UPI_ID_PAGE,
        metadata: { amount: paymentGateway?.amountPayable },
      })
    }
  }, [isOpen])

  const verifyAndProceed = async () => {
    let accountId = user?.account?.id
    let data = {
      accountId,
      vpaId: upiId,
      pgId: paymentGateway?.pgconfig[0]?.id,
      pgFlowId: paymentGateway?.pgconfig[0]?.flows[0]?.id,
    }
    captureEvents({
      eventName: EventName.PAY_BILL_UPI_VERIFY_BTN,
      metadata: { amount: paymentGateway?.amountPayable, upiId: upiId },
    })

    try {
      const response = await AccountService.verifyVpa(data)
      if (response.status === 200) {
        setAccHolderName(response.data?.data.accountHolderName)
        setVpaTxnId(response.data?.data?.vpaTxnRefId)
        dispatch(
          setPgVPA({
            vpaTxnRefId: response?.data?.data?.vpaTxnRefId,
            accountHolderName: response?.data?.data?.accountHolderName,
            vpaId: upiId,
          }),
        )

        let initaiteTransactionData = {
          accountId: user.account.id,
          vpaTxnRefId: response?.data?.data?.vpaTxnRefId,
          amount: paymentGateway.amountPayable,
          pgId: paymentGateway?.pgconfig[0]?.id,
          pgFlowId: paymentGateway?.pgconfig[0]?.flows[0]?.id,
        }

        captureEvents({
          eventName: EventName.PAY_BILL_UPI_ID_CTA,
          metadata: { amount: paymentGateway?.amountPayable, upiId: upiId },
        })

        try {
          setSubmitLoading(true)
          const initiateApiresponse =
            await AccountService.initiatePGTransaction(initaiteTransactionData)
          if (initiateApiresponse.status == 200) {
            dispatch(
              setPgTransaction({
                txnRefId: initiateApiresponse.data?.data?.txnRefId,
                redirectUrl: initiateApiresponse.data?.data?.redirectUrl,
              }),
            )
            setSubmitLoading(false)
            navigate("/PaymentGateway/paymentRequest")
          } else {
            setSubmitLoading(false)
            navigate("/PaymentGateway/Failed")
          }
        } catch (err) {
          setSubmitLoading(false)
          navigate("/PaymentGateway/Failed")
        }

        resetErrorTryAgainCount()
      } else {
        setErrorState(response?.status, () => verifyAndProceed())
        setIsError(true)
        return
      }
    } catch (err) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR, () => verifyAndProceed())
      } else {
        setIsError(true)
        setErrorState(ErrorType.INTERNAL_ERROR, () => verifyAndProceed())
      }
      return
    }
  }

  return (
    <BottomSheet open={isOpen} onDismiss={onClose}>
      <div className='v3-paymentUpiVerify-header'>
        <div>{t("PaymentGateway.enterUpiIdManuallyText")}</div>
        <div onClick={onClose}>
          <Cross />
        </div>
      </div>
      <Divider />
      <div className='v3-paymentUpiVerify-content'>
        <div>{t("PaymentGateway.enterUpiIdToVerifyText")}</div>
        <input
          value={upiId}
          onChange={e => {
            setIsError(false)
            setUpiId(e.target.value)
          }}
          className={`v3-paymentUpiVerify-upiId-input ${isError ? "v3-paymentUpiVerify-upiId-input-error" : ""}`}
        />
        {isError && (
          <div className='v3-paymentUpiVerify-invalid-upiId'>
            <div>
              <Info iconProps={{ fill: "red", size: "16px" }} />
            </div>
            {t("PaymentGateway.invalidUpiIdText")}
          </div>
        )}
        <ColorButton
          text={t("PaymentGateway.verifyAndProceedText")}
          width='100%'
          onPress={verifyAndProceed}
          isDisable={isError}
        />
      </div>
    </BottomSheet>
  )
})

export default PaymentUpiVerifyV3
